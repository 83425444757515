import React, { useState } from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { theme } from "../utils/global-styles/theme";
import Layout from "../components/Layout/Layout";
import ConfirmCode from "../components/Registration/child-components/ConfirmCodeForm";
import ForgotPasswordForm from "../components/ForgotPassword/ForgotPasswordBody";
import useForgotPasswordData from "../hooks/forgot-password-data";
import { _post } from "../utils/services/api.service";

const SectionContainer = styled.section`
    padding: 120px 328px;
    @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
      padding: 2em 1em 3em 1em;
    }
  `,
  ConfirmCodeContainer = styled.section`
    padding: 120px;
    @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
      padding: 2em 1em 3em 1em;
    }
  `,
  ConfirmCodeWrapperStyles = `
  grid-area: 2 / 2 / span 1 / span 3;
  padding-left: 240px;
  @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
    padding: 0;
    grid-area: 1 / 2 / span 3 / span 1;
  }`,
  RegisterFormLabelStyles = css`
    font-weight: bold;
    font-family: ${theme.fontFaces.inputPlaceholders};
    position: relative;
    top: -0.75em;
    background-color: ${theme.colors.background};
    padding: 0 8px;
    left: 4px;
  `,
  RegisterFormWrapperStyles = css`
    border: 2px solid ${theme.colors.accent};
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px 16px 12px;
    margin: 15px 0;
    @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
      width: 100%;
    }
  `,
  PasswordReset = ({ data, pageContext: { locale } }) => {
    const [emailError, setEmailError] = useState(null),
      { heading, shortDescription, contentModules } = data.reset.nodes[0],
      [email, setEmail] = useState(""),
      [loading, setLoading] = useState(false),
      [stage, setStage] = useState("Enter email"),
      [confirmationCodeError, setConfirmationCodeError] = useState(null),
      [passwordError, setPasswordError] = useState(null),
      [confirmPasswordError, setConfirmPasswordError] = useState(null),
      [confirmationCode, setConfirmationCode] = useState(null),
      [newPassword, setNewPassword] = useState(""),
      [verifyPassword, setVerifyPassword] = useState(""),
      handleCancel = () => {
        if (typeof window === "object") {
          window.location.replace(`${process.env.GATSBY_login_url}sso`);
        }
        return false;
      },
      submitEmailAddress = async (e, values) => {
        setLoading(true);
        setEmail(values.email);
        values.first_name = "";
        values.locale = locale;
        const response = await _post({
          isPublic: true,
          endpoint: `accounts/send-emailtoken`,
          args: values,
        });
        if (!response.success) {
          setLoading(false);
          setEmailError(response.errors[0].message);
        } else {
          setLoading(false);
          setStage("Enter code");
        }
      },
      submitNewPassword = async (e, values) => {
        const passwordRegex = new RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})"
        );
        if (!passwordRegex.test(values.new_password)) {
          setPasswordError(
            contentModules[0].options[1] ||
              "Must have at least 6 letters, 1 number, and 1 capital letter"
          );
        } else if (values.confirm_password !== values.new_password) {
          setConfirmPasswordError(
            contentModules[0].options[2] || "Passwords must match"
          );
        } else {
          Object.assign(values, {
            email: email,
            stage: 2,
            token: confirmationCode,
          });
          setLoading(true);
          const response = await _post({
            isPublic: true,
            endpoint: `accounts/reset-password`,
            args: values,
          });
          if (response.success) {
            setLoading(false);
            setStage("Confirmation");
          } else {
            setLoading(false);
            response.errors.map(err => setConfirmPasswordError(err.message));
          }
        }
      },
      redirectToLogin = () => {
        window.location.replace(`${process.env.GATSBY_login_url}sso`);
      };
    const emailLabel = locale === "es" ? "Correo" : "Email";
    const submitButtonText = locale === "es" ? "Enviar" : "Submit";
    const cancelButtonText = locale === "es" ? "Cancelar" : "Cancel";
    return (
      <Layout loading={loading} locale={locale}>
        {stage === "Enter email" && (
          <SectionContainer>
            <ForgotPasswordForm
              buttonText={submitButtonText}
              cancelText={cancelButtonText}
              header={heading}
              subheader={shortDescription}
              setEmailError={setEmailError}
              emailError={emailError}
              handleSubmit={submitEmailAddress}
              handleCancel={handleCancel}
              isCancel={true}
              formValues={{
                name: "email",
                label: emailLabel,
                value: "",
                inputType: "text",
                inputSubType: "email",
                styles: {
                  labelStyles: RegisterFormLabelStyles,
                  valueStyles: css`
                    font-size: 18px;
                  `,
                  wrapperStyles: RegisterFormWrapperStyles,
                },
                required: true,
                handleValidation: event => {
                  const emailRegex = new RegExp(
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  );
                  if (!emailRegex.test(event.target.value)) {
                    setEmailError(
                      contentModules[0].options[0] ||
                        "You must enter a valid email address."
                    );
                  } else {
                    setEmailError(null);
                  }
                },
                error: emailError,
              }}
            />
          </SectionContainer>
        )}
        {stage === "Enter code" && (
          <ConfirmCodeContainer>
            <ConfirmCode
              confirmCodeCopy={data?.confirmCode?.nodes[0]}
              handleSubmit={async (e, values) => {
                setLoading(true);
                Object.assign(values, {
                  email: email,
                  stage: 1,
                });
                const response = await _post({
                  isPublic: true,
                  endpoint: `accounts/reset-password`,
                  args: values,
                });
                if (!response.success) {
                  setConfirmationCodeError(response.errors[0].message);
                  setLoading(false);
                } else {
                  setConfirmationCode(values.token);
                  setLoading(false);
                  setStage("Enter new password");
                }
              }}
              wrapperStyles={ConfirmCodeWrapperStyles}
              error={confirmationCodeError}
              email={email}
              firstName=""
              locale={locale}
            />
          </ConfirmCodeContainer>
        )}
        {stage === "Enter new password" && (
          <SectionContainer>
            <ForgotPasswordForm
              buttonText={submitButtonText}
              cancelText={cancelButtonText}
              header={contentModules[0].title}
              subheader={contentModules[0].description.description}
              isCancel={false}
              handleSubmit={submitNewPassword}
              formValues={[
                {
                  name: "new_password",
                  label: contentModules[0].options[3],
                  value: newPassword,
                  inputSubType: "password",
                  inputType: "text",
                  styles: {
                    labelStyles: RegisterFormLabelStyles,
                    valueStyles: css`
                      font-size: 18px;
                    `,
                    wrapperStyles: RegisterFormWrapperStyles,
                  },
                  required: false,
                  handleValidation: (event, setError) => {
                    setNewPassword(event.target.value);
                    const passwordRegex = new RegExp(
                      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})"
                    );
                    if (!passwordRegex.test(event.target.value)) {
                      setPasswordError(
                        contentModules[0].options[1] ||
                          "Must have at least 6 letters, 1 number, and 1 capital letter"
                      );
                    } else {
                      setError(null);
                    }
                  },
                  error: passwordError,
                  helpText:
                    contentModules[0].options[1] ||
                    "Must have at least 6 letters, 1 number, and 1 capital letter",
                },
                {
                  name: "confirm_password",
                  label: contentModules[0].options[4],
                  inputType: "text",
                  inputSubType: "password",
                  styles: {
                    labelStyles: RegisterFormLabelStyles,
                    valueStyles: css`
                      font-size: 18px;
                    `,
                    wrapperStyles: RegisterFormWrapperStyles,
                  },
                  required: false,
                  value: verifyPassword,
                  handleValidation: (event, setError) => {
                    setVerifyPassword(event.target.value);
                    if (newPassword !== event.target.value) {
                      setConfirmPasswordError(
                        contentModules[0].options[2] || "Passwords must match"
                      );
                    } else {
                      setConfirmPasswordError(null);
                    }
                  },
                  error: confirmPasswordError,
                },
              ]}
            />
          </SectionContainer>
        )}
        {stage === "Confirmation" && (
          <SectionContainer>
            <ForgotPasswordForm
              header={contentModules[1].title}
              subheader={contentModules[1].description.description}
              buttonText={contentModules[1].link.linkText}
              isCancel={false}
              handleSubmit={redirectToLogin}
              formValues={{
                value: "",
                styles: {
                  wrapperStyles: css`
                    display: none;
                  `,
                },
              }}
            />
          </SectionContainer>
        )}
      </Layout>
    );
  };

export const query = graphql`
  query($locale: String!) {
    reset: allContentfulPageLayout(
      filter: {
        queryIdentifier: { eq: "Forgot Password Page" }
        node_locale: { eq: $locale }
      }
    ) {
      nodes {
        heading
        shortDescription
        contentModules {
          ... on ContentfulTitleDescription {
            title
            description {
              description
            }
            options
            link {
              linkText
              url
            }
          }
        }
      }
    }
    confirmCode: allContentfulPageLayout(
      filter: {
        queryIdentifier: { eq: "Reconciliation & Migration Flow" }
        node_locale: { eq: $locale }
      }
    ) {
      nodes {
        node_locale
        contentModules {
          ... on ContentfulInformationSection {
            id
            informationSection {
              informationSection
            }
          }
          ... on ContentfulMigrationAndReconciliation {
            name
            title
            subTitle
            options
            finePrint {
              finePrint
            }
            cta {
              linkText
            }
            errorMessage
            inputBox
          }
        }
      }
    }
  }
`;

export default PasswordReset;
