import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { theme } from "../../utils/global-styles/theme";
import DynamicForm from "../shared/DynamicForm/DynamicForm";
import PropTypes from "prop-types";
import { navigate } from "gatsby";

const InstructionText = styled.h5`
    margin-top: 10px;
    margin-bottom: 50px;
    font-size: 1.2em;
    line-height: 1.5em;
    @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
      font-size: 1.1rem;
    }
  `,
  ForgotPasswordBody = ({
    handleSubmit,
    formValues,
    isCancel,
    handleCancel,
    header,
    subheader,
    buttonText,
    cancelText,
  }) => {
    return (
      <>
        <h3>{header}</h3>
        <InstructionText>{subheader}</InstructionText>
        <DynamicForm
          formValues={[
            ...(Array.isArray(formValues) ? formValues : []),
            formValues,
          ]}
          confirmButton={{
            buttonText: buttonText || "Submit",
            styles: {
              buttonStyle: css`
                border-width: 1px;
                border-style: solid;
                margin-right: 2em;
              `,
              buttonTextStyles: css`
              `,
            },
            confirmButtonClasses: "black-button",
          }}
          submit={handleSubmit}
          cancel={event => {
            event.preventDefault();
            if (handleCancel) {
              handleCancel();
            } else {
              navigate("/");
            }
          }}
          formStyles={css`
            display: block;
          `}
          cancelButton={{
            buttonText: cancelText,
            styles: {
              buttonStyles: css`
                display: ${isCancel ? "inline-block" : "none"};
              `,
              buttonTextStyles: css`
              `,
            },
            cancelButtonClasses: "black-button",
          }}
        />
      </>
    );
  };

ForgotPasswordBody.propTypes = {
  handleSubmit: PropTypes.func,
  formValues: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    styles: PropTypes.objectOf(PropTypes.string),
    value: PropTypes.string,
    inputType: PropTypes.string,
    inputSubType: PropTypes.string,
    required: PropTypes.bool,
    helpText: PropTypes.string,
    handleValidation: PropTypes.func,
    error: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  isCancel: PropTypes.bool,
  handleCancel: PropTypes.func,
  header: PropTypes.string,
  subheader: PropTypes.string,
  buttonText: PropTypes.string,
};

export default ForgotPasswordBody;
